<template>

  <div class="row">
    <div class="col-md-12">
      <div class="card card-body printableArea"><h3>
        <b>Laporan Surat Keluar Grafik</b>
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <form method="post" @submit.prevent="filterData">
              <div class="form-body">
                <div class="row">
                  <div class="pull-left">
                    <div class="form-group row">
                      <label class="control-label text-right mt-2 col-md-5">Periode Tahun</label>
                      <div class="col-md-7">
                        <input type="text" class="form-control" placeholder="Pencarian Tahun" v-model="tahun" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="pull-left">
                    <div class="form-group row">
                      <label class="control-label text-right mt-2 col-md-5">Nama Pembuat</label>
                      <div class="col-md-7">
                        <input 
                          type="text"
                          placeholder="NIP / NIK"
                          class="form-control"
                          v-model="pembuat"
                        />
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="row">
                  <div class="pull-left">
                    <div class="form-group row">
                      <label class="control-label text-right mt-2 col-md-5">Unit / OPD</label>
                      <div class="col-md-7">
                        <input type="text" placeholder="Unit / OPD" class="form-control" v-model="unit">
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <hr>
              <div class="form-actions">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-offset-3 col-md-9">
                        <button type="submit" class="btn btn-success" title="Simpan">Submit</button>&nbsp;
                        <button type="button" @click="resetInput()" class="btn btn-inverse" title="Reset">Reset</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                </div>
              </div>
            </form>
          </div>
          <hr/>
          <div class="col-md-12 justify-content-md-center">
          </div>

          <!-- <div class="col-md-6">
            <div class="card">
              <div class="row">
                <div>
                  <div class="card-body">
                    <h4 class="font-medium text-inverse">Grafik Pengirim Surat Keluar</h4>
                    <ul class="list-inline">
                      <li class="p-l-0">
                        <h6 class="text-muted"></h6>
                      </li>
                    </ul>
                    <div class="total-revenue4">
                      <line-chart :chart-data="lineUnitKerja"></line-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div class="col-md-6">
            <div class="card">
              <div class="row">
                <div>
                  <div class="card-body">
                    <h4 class="font-medium text-inverse">Grafik Penerima Surat Keluar</h4>
                    <ul class="list-inline">
                      <li class="p-l-0">
                        <h6 class="text-muted"></h6>
                      </li>
                    </ul>
                    <div class="total-revenue4">
                      <line-chart :chart-data="lineOPDSK"></line-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="col-md-12">
            <div class="card">
              <div class="row">
                <div>
                  <div class="card-body">
                    <h4 class="font-medium text-inverse">Grafik Surat Keluar</h4>
                    <ul class="list-inline">
                      <li class="p-l-0">
                        <h6 class="text-muted"></h6>
                      </li>
                    </ul>
                    <div class="total-revenue4">
                      <line-chart :chart-data="lineBulan"></line-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import moment from 'moment';
import { RotateSquare5 } from "vue-loading-spinner";
import { LineChart, BarChart } from "@/components/chart";
import axios from 'axios';

export default {
  components: {
    RotateSquare5,
    Table,
    "line-chart": LineChart,
    "bar-chart": BarChart
  },
  data() {
    return {
      start_date : moment().startOf('year').format('YYYY-MM-DD'),
      end_date : moment().endOf('year').format('YYYY-MM-DD'),
      pembuat: '',
      unit: '',
      tahun : '',
      tanggal : new Date().getDate()+'-'+(new Date().getMonth()+1)+'-'+new Date().getFullYear(),
      detail_bulan : {
        namabulan: this.$store.state.laporanDokumenOutGrafik.items.finaldatabulan
      },
      detail_ss: {
        namass: this.$store.state.laporanDokumenOutGrafik.items.finaldatass
      },
      detail_nnd: {
        namannd: this.$store.state.laporanDokumenOutGrafik.items.finaldatannd
      },
      detail_jnd: {
        namajnd: this.$store.state.laporanDokumenOutGrafik.items.finaldatajnd
      },
      detail_knd: {
        namaknd: this.$store.state.laporanDokumenOutGrafik.items.finaldataknd
      },
    };
  },
  computed: {
    items() {
      return this.$store.state.laporanDokumenOutGrafik.items ? this.$store.state.laporanDokumenOutGrafik.items.items : [];
    },
    chartDataBulan(){
      let chartDataBulan = {
        labels : [],
        datasets: [
          {
            backgroundColor: ["#55ce63","#009efb", "#ffbc34","#CCB328","#AFCC28","#28CC7B","#28CCA0","#28CCBF","#28AECC","#2867CC","#8D28CC","#CC28B0"],
            data: [],
          }
        ]
      }
      return chartDataBulan;
    },
    chartDataSS(){
      let chartDataSS = {
        labels : [],
        datasets: [
          {
            backgroundColor: ["#55ce63","#009efb", "#ffbc34"],
            data: [],
          }
        ]
      }
      return chartDataSS;
    },

    chartDataJND(){
      let chartDataJND = {
        labels : [],
        datasets: [
          {
            backgroundColor: ["#55ce63","#009efb", "#ffbc34","#CCB328","#AFCC28","#28CC7B","#28CCA0","#28CCBF","#28AECC","#2867CC","#8D28CC","#CC28B0"],
            data: [],
          }
        ]
      }
      return chartDataJND;
    },

    chartDataNND(){
      let chartDataNND = {
        labels : [],
        datasets: [
          {
            backgroundColor: ["#55ce63","#009efb", "#ffbc34","#CCB328","#AFCC28","#28CC7B","#28CCA0","#28CCBF","#28AECC","#2867CC","#8D28CC","#CC28B0"],
            data: [],
          }
        ]
      }
      return chartDataNND;
    },

    chartDataKND(){
      let chartDataKND = {
        labels : [],
        datasets: [
          {
            backgroundColor: ["#55ce63","#009efb", "#ffbc34","#CCB328","#AFCC28","#28CC7B","#28CCA0","#28CCBF","#28AECC","#2867CC","#8D28CC","#CC28B0"],
            data: [],
          }
        ]
      }
      return chartDataKND;
    },
    lineBulan(){
      // let vm = this;
      let lineBulan = {
        labels: this.$store.state.laporanDokumenOutGrafik.items.finaldatabulannamabulan,
        datasets: [
          {
            label: "Surat Masuk",
            data: this.$store.state.laporanDokumenOutGrafik.items.finaldatabulantotalbulan,
            borderDash: [5, 5],
            borderWidth: 1,
            borderColor: "#55ce63",
            pointBorderColor: "#55ce63",
            backgroundColor: "rgba(85, 206, 99, 0.55)",
            pointBackgroundColor: "transparent"
          },
        ]
      }

      return lineBulan;
    },
    lineSS(){
      // let vm = this;
      let lineBulan = {
        labels: this.$store.state.laporanDokumenOutGrafik.items.finaldatassnamass,
        datasets: [
          {
            label: "Surat Masuk",
            data: this.$store.state.laporanDokumenOutGrafik.items.finaldatasstotalss,
            borderDash: [5, 5],
            borderWidth: 1,
            borderColor: "#55ce63",
            pointBorderColor: "#55ce63",
            backgroundColor: "rgba(85, 206, 99, 0.55)",
            pointBackgroundColor: "transparent"
          },
        ]
      }

      return lineBulan;
    },
    lineJND(){
      // let vm = this;
      let lineBulan = {
        labels: this.$store.state.laporanDokumenOutGrafik.items.finaldatajndnamajnd,
        datasets: [
          {
            label: "Surat Masuk",
            data: this.$store.state.laporanDokumenOutGrafik.items.finaldatajndtotaljnd,
            borderDash: [5, 5],
            borderWidth: 1,
            borderColor: "#55ce63",
            pointBorderColor: "#55ce63",
            backgroundColor: "rgba(85, 206, 99, 0.55)",
            pointBackgroundColor: "transparent"
          },
        ]
      }

      return lineBulan;
    },
    lineNND(){
      // let vm = this;
      let lineBulan = {
        labels: this.$store.state.laporanDokumenOutGrafik.items.finaldatanndnamannd,
        datasets: [
          {
            label: "Surat Masuk",
            data: this.$store.state.laporanDokumenOutGrafik.items.finaldatanndtotalnnd,
            borderDash: [5, 5],
            borderWidth: 1,
            borderColor: "#55ce63",
            pointBorderColor: "#55ce63",
            backgroundColor: "rgba(85, 206, 99, 0.55)",
            pointBackgroundColor: "transparent"
          },
        ]
      }

      return lineBulan;
    },
    lineKND(){
      // let vm = this;
      let lineBulan = {
        labels: this.$store.state.laporanDokumenOutGrafik.items.finaldatanndnamaknd,
        datasets: [
          {
            label: "Surat Masuk",
            data: this.$store.state.laporanDokumenOutGrafik.items.finaldatanndtotalknd,
            borderDash: [5, 5],
            borderWidth: 1,
            borderColor: "#55ce63",
            pointBorderColor: "#55ce63",
            backgroundColor: "rgba(85, 206, 99, 0.55)",
            pointBackgroundColor: "transparent"
          },
        ]
      }

      return lineBulan;
    },
    lineUnitKerja(){
      // let vm = this;
      let lineUnitKerja = {
        labels: this.$store.state.laporanDokumenOutGrafik.items.finaldataunitnamaunitpenerima,
        datasets: [
          {
            label: "Surat Keluar",
            data: this.$store.state.laporanDokumenOutGrafik.items.finaldataunittotalunitpenerima,
            borderDash: [5, 5],
            borderWidth: 1,
            borderColor: "#55ce63",
            pointBorderColor: "#55ce63",
            backgroundColor: "rgba(85, 206, 99, 0.55)",
            pointBackgroundColor: "transparent"
          },
        ]
      }

      return lineUnitKerja;
    },
    lineOPDSK(){
      // let vm = this;
      let lineOPDSK = {
        labels: this.$store.state.laporanDokumenOutGrafik.items.finaldataunitnamaunitpenerima,
        datasets: [
          {
            label: "Penerima Surat Keluar",
            data: this.$store.state.laporanDokumenOutGrafik.items.finaldataunittotalunitpenerima,
            borderDash: [5, 5],
            borderWidth: 1,
            borderColor: "#55ce63",
            pointBorderColor: "#55ce63",
            backgroundColor: "rgba(85, 206, 99, 0.55)",
            pointBackgroundColor: "transparent"
          },
        ]
      }

      return lineOPDSK;
    },
    // lineBulan(){
    //   // let vm = this;
    //   let lineBulan = {
    //     labels: this.$store.state.laporanDocumentOutGrafik.items.finalbulannamabulan,
    //     datasets: [
    //       {
    //         label: "Surat Masuk",
    //         data: this.$store.state.laporanDocumentOutGrafik.items.finalbulantotalbulan,
    //         borderDash: [5, 5],
    //         borderWidth: 1,
    //         borderColor: "#55ce63",
    //         pointBorderColor: "#55ce63",
    //         backgroundColor: "rgba(85, 206, 99, 0.55)",
    //         pointBackgroundColor: "transparent"
    //       },
    //     ]
    //   }

    //   return lineBulan;
    // },
    prints() {
      return this.$store.state.laporanDokumenOutGrafik.items ? this.$store.state.laporanDokumenOutGrafik.items.prints : [];
    },
    databulan() {
      return this.$store.state.laporanDokumenOutGrafik.items.finaldatabulan ? this.$store.state.laporanDokumenOutGrafik.items.finaldatabulan : [];
    },
    datass() {
      return this.$store.state.laporanDokumenOutGrafik.items.finaldatass ? this.$store.state.laporanDokumenOutGrafik.items.finaldatass : [];
    },
    datannd() {
      return this.$store.state.laporanDokumenOutGrafik.items.finaldatannd ? this.$store.state.laporanDokumenOutGrafik.items.finaldatannd : [];
    },    
    filters() {
      return this.$store.state.laporanDokumenOutGrafik.items ? this.$store.state.laporanDokumenOutGrafik.items.filters: {};
    },
    state() {
      return this.$store.state.laporanDokumenOutGrafik;
    },
    forbidUpdate(){
      if(this.$store.state.profile.permissions.laporan_docout_grafik){
        return !this.$store.state.profile.permissions.laporan_docout_grafik.update;
      }
      return false;
    },
    forbidRead(){
      if(this.$store.state.profile.permissions.laporan_docout_grafik){
        return !this.$store.state.profile.permissions.laporan_docout_grafik.read;
      }
      return false;
    },
    forbidDel(){
      if(this.$store.state.profile.permissions.laporan_docout_grafik){
        return !this.$store.state.profile.permissions.laporan_docout_grafik.delete;
      }
      return false;
    }
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      prints: [],
    };
    this.$store.commit("laporanDokumenOutGrafik/STATE", state);
    this.get();
  },
  methods: {
    get(val) {
      this.$store.dispatch("laporanDokumenOutGrafik/getLaporanDokumenOutGrafik", val);
    },
    resetInput() {
      this.start_date = "";
      this.end_date = "";
      this.pembuat = "";
      this.unit = "";
      this.tahun = "";
      this.get(val);
    },
    pagination(params) {
      this.filterData(null, params);
    },
    filterData: function (event, params) {
      var val = {
        start_date: this.start_date,
        end_date: this.end_date,
        pembuat: this.pembuat,
        tahun: this.tahun,
        unit: this.unit,
      }
      if(typeof(params) == "object" && params.constructor.name === "Object") {
        val = Object.assign(params, val);
      }
      this.get(val);
    },
    doDetail(val) {
      this.$store.dispatch("laporanDokumenOutGrafik/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("laporanDokumenOutGrafik/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("laporanDokumenOutGrafik/submitDelete", val);
    },

  }
};
</script>
